<template>
  <div class="role-container">
    <ContentTitle>收款账户</ContentTitle>

    <Wtable
      :columns="columns"
      :showPagin="true"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
    >
      <template #parkingNumber="{ row }">
        <div class="parking-number" @click="openDetailDialog(row)">
          {{ row.parkingNumber }}
        </div>
      </template></Wtable
    >
    <Wdialog
      ref="detailDialog"
      title="关联车场"
      width="50%"
      @wConfirm="handleApply"
      class="multiple-dialog"
    >
      <div class="multiple-content">
        <div
          v-for="(el, index) in detailData"
          :key="index"
          class="toplevel-container"
        >
          <div class="toplevel-title">
            {{ el.title }}
          </div>
          <div class="sublevel-container">
            <div
              v-for="(el2, index2) in el.data"
              :key="index2"
              class="sublevel-item text-hidden"
              :class="selectparkings.includes(el2.id) ? 'sublevel-active' : ''"
              @click="handleSelectParking(el2)"
            >
              {{ el2.title }}
            </div>
          </div>
        </div>
      </div>
    </Wdialog>
  </div>
</template>
<script>
import { ref } from "vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./collection.json";

export default {
  components: {
    Wtable,
    Wdialog,
    ContentTitle,
  },
  setup() {
    const columns = config.tableConfig.list;
    const tableData = ref([
      {
        companyName: "鲁滨逊的企业",
        openType: "荒岛上",
        contactPeople: "鲁滨逊",
        contactPhone: "3636251478",
        companyEmail: "366254141225@qq.com",
        merchantNumber: "22222222225",
        parkingNumber: "23",
        projectIds: [1, 6, 11],
      },
    ]);

    const pagination = ref({ total: 9 });
    const currentChange = (page) => {
      console.log(page);
    };
    const detailDialog = ref(null);
    const detailData = ref([
      {
        title: "项目名称",
        data: [
          {
            id: 1,
            title: "车场名称车场名称车场名称",
          },
          {
            id: 2,
            title: "车场名称",
          },
          {
            id: 3,
            title: "车场名称",
          },
          {
            id: 4,
            title: "车场名称",
          },
          {
            id: 5,
            title: "车场名称",
          },
          {
            id: 6,
            title: "车场名称",
          },
          {
            id: 7,
            title: "车场名称",
          },
          {
            id: 8,
            title: "车场名称",
          },
        ],
      },
      {
        title: "项目名称",
        data: [
          {
            id: 11,
            title: "车场名称车场名称车场名称",
          },
          {
            id: 22,
            title: "车场名称",
          },
          {
            id: 33,
            title: "车场名称",
          },
          {
            id: 44,
            title: "车场名称",
          },
          {
            id: 55,
            title: "车场名称",
          },
          {
            id: 66,
            title: "车场名称",
          },
          {
            id: 77,
            title: "车场名称",
          },
          {
            id: 88,
            title: "车场名称",
          },
        ],
      },
    ]);
    const selectparkings = ref([]);
    function openDetailDialog(data) {
      selectparkings.value = data.projectIds;
      detailDialog.value.show();
    }
    function handleSelectParking(data) {
      const index = selectparkings.value.findIndex((item) => item == data.id);
      index != -1
        ? selectparkings.value.splice(index, 1)
        : selectparkings.value.splice(
            selectparkings.value.length - 1,
            0,
            data.id
          );
    }
    function handleApply() {}
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      handleApply,
      detailDialog,
      detailData,
      handleSelectParking,
      openDetailDialog,
      selectparkings,
    };
  },
};
</script>

<style lang='scss'>
.role-container {
  .parking-number {
    width: 32px;
    text-align: center;
    color: #ffffff;
    background-color: var(--theme-color);
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>